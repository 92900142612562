import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    isVisible: false,
    notification: {
      status: "info",
      title: "",
      message: "",
    },
  },
  reducers: {
    toggleNotification(state) {
      state.isVisible = !state.isVisible;
    },
    showNotification(state, action) {
      state.isVisible = true;
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;

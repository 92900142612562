import { uiActions } from "../slices/ui";
import { imageActions } from "../slices/image";
import { config } from "../config";

import { sendRequest } from "./auth";

const API_URL = `https://api-utils4.mobi-me.pl/${config.IS_DEV ? "cms_dev" : "cms"}`;

export const fetchImageData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const imageData = await dispatch(sendRequest("image", filters));

      dispatch(imageActions.setImage(imageData));
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Loading data failed!",
        })
      );
    }
    return Promise.resolve();
  };

export const uploadFile = (file) => async (dispatch) => {
  let fnResult = true;

  const saveData = async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${API_URL}/image/upload/?token=${token}`, {
      method: "POST",
      body: file,
    });

    if (!response.ok) {
      const error = new Error("Saving failed!");
      error.code = response.status;
      throw error;
    }

    const data = response.json();

    return data;
  };

  try {
    const result = await saveData();

    if (result) {
      fnResult = result;
      await dispatch(fetchImageData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Zdjęcie dodane!",
        })
      );
    } else {
      throw new Error("Image saving error!");
    }
  } catch (err) {
    console.log(err);
    let message = "Błąd zapisu danych!";

    if (err.code === 403) {
      message = "Niepoprawna nazwa pliku!";
    }
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: message,
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const saveActiveStatus = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("image/status", record, "PUT"));

    if (result) {
      fnResult = result.data;
      await dispatch(fetchImageData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Zdjęcie usunięte!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    let message = "Błąd zapisu danych!";

    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: message,
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

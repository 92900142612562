import PollEditSection from "../components/PollEdit/PollEditSection";
import useQuery from "../hooks/useQuery";

const HomePage = () => {
  const query = useQuery();

  return <PollEditSection type={query.get("type")} id={query.get("id")} mode={query.get("mode")} />;
};

export default HomePage;

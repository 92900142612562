import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TextField, Button, Switch, FormControlLabel, Tooltip } from "@mui/material";

import { saveAnswerData, fetchAnswerData } from "../../store/actions/answer";

import ExistingAnswers from "./ExistingAnswers";
import ImageModal from "../Image/ImageModal";
import classes from "./AnswerEdit.module.css";

const defaultForm = {
  id: null,
  content: "",
  position: 1,
  question_id: null,
  image_id: null,
  is_correct: false,
};

const imageRootUrl = "https://quizyrmf.test-site.net.pl/";

function AnswerEdit({ id, question_id, poll_id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question.question);
  const answers = useSelector((state) => state.answer.answer);
  const imageData = useSelector((state) => state.image.image);

  const [form, setForm] = useState(JSON.parse(JSON.stringify(defaultForm)));
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [header, setHeader] = useState(`Nowe odpowiedź pytania '${selectedQuestion.content}'`);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageID, setSelectedImageID] = useState();
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    let foundQuestion = question[poll_id].find((x) => x.id === +question_id);
    setSelectedQuestion(foundQuestion);
  }, [question, poll_id, question_id]);

  useEffect(() => {
    if (!answers[question_id] && question_id) {
      dispatch(fetchAnswerData({ question_id: +question_id }));
    }
  }, [question_id, answers, dispatch]);

  useEffect(() => {
    if (id && question_id && Object.keys(answers).length > 0) {
      const answer = answers[question_id].find((x) => x.id === +id);
      if (answer) {
        setHeader(`Edycja odpowiedzi ${id} pytania '${selectedQuestion.content}'`);

        setForm({
          id: +id,
          content: answer.content,
          position: answer.position,
          question_id: answer.question_id,
          image_id: answer.image_id,
          is_correct: answer.is_correct === 1 ? true : false,
        });

        setSelectedImageID(answer.image_id);
        const foundImage = imageData.find((x) => x.id === +answer.image_id);
        if (foundImage) {
          setSelectedImage(foundImage.image_url);
        }
      }
    } else if (question_id) {
      setHeader(`Nowa odpowiedź pytania '${selectedQuestion.content}'`);

      let newForm = JSON.parse(JSON.stringify(defaultForm));
      if (answers[question_id]) {
        newForm.position = answers[question_id].length + 1;
      }
      newForm.question_id = +question_id;
      setForm(newForm);
    }
  }, [id, question_id, answers, selectedQuestion, imageData]);

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = { ...form };
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    if (target === "is_correct") {
      newForm[target] = event.target.checked;
    } else {
      newForm[target] = event.target.value;
    }

    setForm(newForm);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    let formObj = JSON.parse(JSON.stringify(form));

    formObj.is_correct = formObj.is_correct ? 1 : 0;
    formObj.poll_id = +poll_id;
    formObj.image_id = selectedImageID;

    let record = {
      ...formObj,
    };

    record.content = record.content.replaceAll('"', "”");

    await dispatch(saveAnswerData(record));
  };

  const handleBackToQuestions = () => {
    navigate(`/question-edit/?poll_id=${poll_id}`);
  };

  const handleModalChange = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.section}>
        <h2>{header}</h2>
        <form onSubmit={submitHandler}>
          <div className={classes.row}>
            <TextField
              id="content"
              name="content"
              required
              label="Treść odpowiedzi"
              variant="outlined"
              onChange={handleFormChange}
              value={form.content}
            />
          </div>
          <div className={classes.row}>
            <TextField
              id="position"
              name="position"
              type="number"
              required
              label="Pozycja"
              variant="outlined"
              onChange={handleFormChange}
              value={form.position}
            />
          </div>
          <div className={classes.row}>
            <FormControlLabel
              control={<Switch name="is_correct" checked={form.is_correct} onChange={handleFormChange} />}
              label="Poprawność odpowiedzi"
            />
          </div>
          <div className={`${classes.row} ${classes.imageRow}`}>
            {selectedImage && (
              <div className={classes.imageDetails}>
                <Tooltip title={selectedImage}>
                  <img src={imageRootUrl + selectedImage} alt="Preview" className={classes.imagePreview} onClick={handleModalChange} />
                </Tooltip>
              </div>
            )}
            <ImageModal
              isOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setSelectedImage={setSelectedImage}
              setSelectedImageID={setSelectedImageID}
              limit={200}
              clearPermitted={true}
            />
            {!selectedImage && <Button onClick={handleModalChange}>Wybierz zdjęcie</Button>}
          </div>
          <div className={classes.row} style={{ marginBottom: 50 }}>
            <Button variant="outlined" type="submit">
              Zapisz
            </Button>
          </div>
        </form>
        <ExistingAnswers answers={answers[question_id]} question_id={question_id} poll_id={poll_id} />
        <div className={`${classes.row} ${classes.buttonRow}`} style={{ marginTop: 50 }}>
          <Button variant="text" sx={{ width: 200 }} size="small" onClick={handleBackToQuestions}>
            Wróć do pytań
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AnswerEdit;

import React, { useEffect, useState } from "react";
import useMountEffect from "../../hooks/useMountEffect";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Typography, Checkbox, FormControlLabel, Tooltip } from "@mui/material";

import classes from "./CategoryPollModal.module.css";

import { useSelector, useDispatch } from "react-redux";

import { savePollCategoryData } from "../../store/actions/poll_category";

const CategoryPollModal = ({ category, isOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const quiz = useSelector((state) => state.poll.quiz);
  const survey = useSelector((state) => state.poll.survey);
  const categoryPoll = useSelector((state) => state.pollCategory.categoryPoll);

  const [border, setBorder] = useState(1);
  const [pollColumns, setPollColumns] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState([]);

  useMountEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (categoryPoll && Object.keys(categoryPoll).length > 0 && categoryPoll[category.id]) {
      setSelectedPoll(categoryPoll[category.id]);
    }
  }, [category.id, categoryPoll, isOpen]);

  const handleResize = () => {
    let width = window.innerWidth;
    let tmpBorder = 0;

    if (width <= 670) {
      tmpBorder = 1;
    } else if (width > 670 && width <= 990) {
      tmpBorder = 2;
    } else if (width > 990 && width <= 1140) {
      tmpBorder = 3;
    } else if (width > 1140 && width <= 1460) {
      tmpBorder = 4;
    } else if (width > 1460) {
      tmpBorder = 5;
    }

    setBorder(tmpBorder);
  };

  useEffect(() => {
    const category_type = category.category_type;
    let pollItems = category_type === "quiz" ? quiz : survey;

    pollItems = [...pollItems].sort((a, b) => (a.name > b.name && 1) || -1);

    for (let i in selectedPoll) {
      if (pollItems.findIndex((el) => el.id === selectedPoll[i]) === -1) {
        pollItems.push({ id: selectedPoll[i], name: `[${selectedPoll[i]}] Nieaktywny` });
      }
    }

    let cnt = 0;

    let row_limit = Math.ceil(pollItems.length / border);
    let row_cnt = 0;

    let tempPollColumns = {};

    for (let step = 0; step < border; step++) {
      tempPollColumns[step] = [];
    }
    for (let i in pollItems) {
      tempPollColumns[cnt].push(pollItems[i]);
      row_cnt++;
      if (row_cnt >= row_limit) {
        cnt++;
        row_cnt = 0;
      }
    }
    setPollColumns(Object.values(tempPollColumns));
  }, [border, category, quiz, survey, selectedPoll]);

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedPoll([...selectedPoll, id]);
    } else {
      const index = selectedPoll.indexOf(id);
      if (index > -1) {
        let newArr = [...selectedPoll];
        newArr.splice(index, 1);
        setSelectedPoll(newArr);
      }
    }
  };

  const categoryLimitCheck = category.min_items && selectedPoll && selectedPoll.length < category.min_items ? true : false;

  const handleClose = () => setIsModalOpen(false);

  const handleAccept = async (e) => {
    if (categoryLimitCheck) return;
    const saveResult = await dispatch(savePollCategoryData({ category_id: category.id, poll: selectedPoll }));
    if (saveResult) {
      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title">
        <Box className={classes.box}>
          <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ margin: "10px 0" }}>
            {category.description}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h7"
            component="div"
            sx={{ margin: "10px 0", color: "rgba(237, 0, 0, 0.8)", textAlign: "center" }}
          >
            {categoryLimitCheck && `Minimalna ilość zestawów w tej kategorii wynosi ${category.min_items}!`}
          </Typography>
          <div className={classes.pollColumns}>
            {pollColumns.length > 0 &&
              pollColumns.map((col, index) => (
                <div key={`col_${index}`} className={classes.pollColumn}>
                  {col.map((row) => {
                    return (
                      <div key={`row_${row.id}`}>
                        <Tooltip title={row.name} placement="top" enterDelay={500} enterNextDelay={500}>
                          <FormControlLabel
                            sx={{ whiteSpace: "nowrap", maxWidth: "200px" }}
                            className={classes.controlLabel}
                            control={
                              <Checkbox
                                onChange={(event) => handleCheckboxChange(event, row.id)}
                                sx={{ padding: "5px" }}
                                checked={selectedPoll && selectedPoll.includes(row.id)}
                                size="small"
                              />
                            }
                            label={row.name}
                          />
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              ))}
          </div>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" disabled={categoryLimitCheck} onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CategoryPollModal;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import classes from "./CategoryRow.module.css";

import { Button } from "@mui/material";
import CategoryPollModal from "./CategoryPollModal";

const CategoryRow = ({ propCategory }) => {
  const poll = useSelector((state) => state.poll.poll);
  const categoryPoll = useSelector((state) => state.pollCategory.categoryPoll);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getPollName = (poll_id) => {
    const foundPoll = poll.find((x) => +x.id === +poll_id);
    if (foundPoll) {
      return foundPoll.name;
    } else {
      return "Nieaktywny";
    }
  };

  const PollCategories = () => {
    if (categoryPoll && categoryPoll[propCategory.id] && poll && poll.length > 0) {
      return categoryPoll[propCategory.id].map((id, i) => (
        <div key={i}>
          [{id}] {getPollName(id)}
        </div>
      ));
    } else {
      return "-";
    }
  };

  const handleCategoryEdit = () => {
    setIsModalOpen(true);
  };

  const isCategoryPollReady = categoryPoll && Object.keys(categoryPoll).length > 0;

  return (
    <div className={classes.section}>
      <div className={classes.listHeader}>
        <h4>{propCategory.description}</h4>
        {isCategoryPollReady && (
          <div>
            <Button variant="outlined" size="small" onClick={handleCategoryEdit}>
              Edytuj kategorię
            </Button>
          </div>
        )}
      </div>
      <div className={classes.listItems}>
        <PollCategories />
      </div>
      {isCategoryPollReady && <CategoryPollModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} category={propCategory} />}
    </div>
  );
};

export default CategoryRow;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useMountEffect from "../../hooks/useMountEffect";

import { fetchCategoryData } from "../../store/actions/category";
import { fetchPollCategoryData } from "../../store/actions/poll_category";
import { fetchPollData } from "../../store/actions/poll";

import CategoryRow from "./CategoryRow";

import classes from "./CategorySection.module.css";
import { authActions } from "../../store/slices/auth";
// import { useEffect } from "react";

function CategorySection() {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category.category);
  const pollCategory = useSelector((state) => state.pollCategory.pollCategory);
  const poll = useSelector((state) => state.poll.poll);
  const isPollFiltered = useSelector((state) => state.poll.filtered);
  const forceReload = useSelector((state) => state.auth.forceReload);

  const loadData = (force) => {
    if (force || (category && Object.keys(category).length <= 0)) {
      dispatch(fetchCategoryData());
    }

    if (force || (pollCategory && Object.keys(pollCategory).length <= 0)) {
      dispatch(fetchPollCategoryData());
    }

    if (force || isPollFiltered || (poll && poll.length <= 0)) {
      dispatch(fetchPollData());
    }
  };

  useMountEffect(() => {
    loadData(false);
  });

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  });

  return (
    <section className={classes.starting}>
      <div className={classes.headerRow}>
        <h2 className={classes.pollHeader}>Kategorie</h2>
      </div>
      <div className={classes.table}>
        {category &&
          Object.values(category).map((x) => {
            return <CategoryRow propCategory={x} key={x.id} />;
          })}
      </div>
    </section>
  );
}

export default CategorySection;

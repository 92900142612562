import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Button,
  Select,
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import moment from "moment";

import { uiActions } from "../../store/slices/ui";
import { savePollData } from "../../store/actions/poll";
import { savePollCategoryData } from "../../store/actions/poll_category";

import ImageModal from "../Image/ImageModal";

import classes from "./PollEditSection.module.css";

const defaultForm = {
  id: null,
  poll_type: "quiz",
  name: "",
  image_id: null,
  image_url: "",
  metric_set_id: 1,
  is_active: "DRAFT",
  creation_date: moment(new Date()),
  expiration_date: null,
};

const defaultCaptions = [
  { name: "banner_title", text: "" },
  { name: "title", text: "" },
  { name: "subtitle", text: "" },
  { name: "description", text: "" },
  { name: "call2action", text: "" },
  { name: "ads_title", text: "" },
];

const captionsLabels = {
  banner_title: "Tytuł banera",
  title: "Tytuł",
  subtitle: "Podtytuł",
  description: "Opis",
  call2action: "Call2Action",
  ads_title: "Tytuł wyświetlany na reklamach",
};

const validation = { name: 50 };

const defaultSummary = [
  { max: 1, min: 0, scoreSummary: "", recomendation: "" },
  { max: 4, min: 2, scoreSummary: "", recomendation: "" },
  { max: 7, min: 5, scoreSummary: "", recomendation: "" },
  { max: 10, min: 8, scoreSummary: "Jesteś w grupie osób z najlepszymi wynikami!", recomendation: "" },
];

const imageRootUrl = "https://quizyrmf.test-site.net.pl/";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function PollEditSection({ type, id, mode }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.auth.profileData);
  const poll = useSelector((state) => state.poll);
  const category = useSelector((state) => state.category.category);
  const pollCategory = useSelector((state) => state.pollCategory.pollCategory);
  const imageData = useSelector((state) => state.image.image);
  const metric = useSelector((state) => state.metric.metric);
  const pollFilters = useSelector((state) => state.poll.filters);

  const [form, setForm] = useState(JSON.parse(JSON.stringify(defaultForm)));
  const [captions, setCaptions] = useState(JSON.parse(JSON.stringify(defaultCaptions)));
  const [summary, setSummary] = useState(JSON.parse(JSON.stringify(defaultSummary)));

  const [header, setHeader] = useState("Nowy zestaw");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageID, setSelectedImageID] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedCategory, setSelectedCategory] = useState(pollCategory[id] || []);

  let statusEditAllowed = false;
  if (profileData && profileData.privileges.includes(144)) {
    statusEditAllowed = true;
  }

  useEffect(() => {
    if (type) {
      let newForm = { ...defaultForm };
      newForm.poll_type = type;
      setForm(newForm);
    }
    if (id) {
      const form = poll[type].find((x) => x.id === +id);
      if (form) {
        let newForm = {
          id: null,
          poll_type: form.poll_type,
          name: "",
          image_id: form.image_id,
          image_url: form.image_url,
          metric_set_id: form.metric_set_id ? form.metric_set_id : "",
          is_active: form.is_active,
          creation_date: moment(new Date()),
          expiration_date: null,
        };

        if (mode === "clone") {
          setHeader(`Klonowanie zestawu '${form.name}'`);
        } else {
          setHeader(`Edycja zestawu '${form.name}'`);
          newForm.id = id;
          newForm.name = form.name;
          newForm.creation_date = form.creation_date;
          newForm.expiration_date = form.expiration_date;
        }

        setForm(newForm);

        setSelectedImageID(form.image_id);
        const foundImage = imageData.find((x) => x.id === +form.image_id);
        if (foundImage) {
          setSelectedImage(foundImage.image_url);
        }

        const captions = JSON.parse(JSON.stringify(defaultCaptions));
        JSON.parse(form.captions).forEach((param) => {
          const foundID = captions.findIndex((x) => x.name === param.name);
          if (foundID !== -1) {
            captions[foundID].text = param.text;
          }
        });

        const summary = JSON.parse(JSON.stringify(defaultSummary));
        if (form.summary) {
          JSON.parse(form.summary).forEach((param) => {
            const foundID = summary.findIndex((x) => +x.min === +param.min && +x.max === +param.max);
            if (foundID !== -1) {
              summary[foundID].recomendation = param.recomendation;
              summary[foundID].scoreSummary = param.scoreSummary;
            }
          });
        }

        setCaptions(captions);
        setSummary(summary);
      }
    }
  }, [poll, type, id, mode, imageData]);

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = { ...form };
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setForm(newForm);
  };

  const handleCaptionsChange = (event) => {
    const target = event.target.name;
    const index = target.split("_").pop();
    let newCaptions = [...captions];
    if (newCaptions[index] && typeof newCaptions[index].text !== "undefined") {
      newCaptions[index].text = event.target.value;
    } else {
      return console.log(target + " not found!");
    }

    setCaptions(newCaptions);
  };

  const handleSummaryChange = (event) => {
    const target = event.target.name;
    const index = target.split("_").pop();
    let newSummary = [...summary];
    if (newSummary[index] && typeof newSummary[index].recomendation !== "undefined") {
      newSummary[index].recomendation = event.target.value;
    } else {
      return console.log(target + " not found!");
    }

    setSummary(newSummary);
  };

  const handleDatePickerChange = (target, newValue) => {
    let newForm = { ...form };
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    if (newValue) {
      newForm[target] = moment(newValue).format("YYYY-MM-DD 00:00:00");
    } else {
      newForm[target] = newValue;
    }

    setForm(newForm);
  };

  const getCaptionLabel = (title) => {
    return captionsLabels[title] ? captionsLabels[title] : title;
  };

  const handleStatusChange = (event) => {
    let newForm = { ...form };
    newForm.is_active = event.target.value;

    setForm(newForm);
  };

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleModalChange = () => {
    setIsModalOpen(!isModalOpen);
  };

  const replaceApostrophe = (str) => {
    return str.replaceAll('"', "”");
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    let formObj = form;

    form.creation_date = moment(form.creation_date).format("YYYY-MM-DD 00:00:00");
    form.expiration_date = form.expiration_date ? moment(form.expiration_date).format("YYYY-MM-DD") : null;
    form.image_url = selectedImage;
    form.image_id = selectedImageID;

    // validation
    for (let i in validation) {
      if (form[i] && form[i].length > validation[i]) {
        return dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Walidacja",
            message: "Niepoprawna treść formularza",
          })
        );
      }
    }

    if (!form.image_id) {
      return dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Walidacja",
          message: "Brak obrazka!",
        })
      );
    }

    if (form.poll_type === "survey" && form.metric_set_id === "") {
      return dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Walidacja",
          message: "Brak metryczki!",
        })
      );
    }

    let record = {
      ...formObj,
      captions: captions,
      summary: summary,
    };

    record.name = replaceApostrophe(record.name);
    if (record.captions) {
      for (let i in record.captions) {
        record.captions[i].text = replaceApostrophe(record.captions[i].text);
      }
    }
    if (record.summary) {
      for (let i in record.summary) {
        record.summary[i].recomendation = replaceApostrophe(record.summary[i].recomendation);
        record.summary[i].scoreSummary = replaceApostrophe(record.summary[i].scoreSummary);
      }
    }

    const savedPollID = await dispatch(savePollData(record, pollFilters));
    if (savedPollID) {
      const pollCategoryData = {
        categories: selectedCategory,
        poll_id: savedPollID,
      };
      const pollCategorySaveResult = await dispatch(savePollCategoryData(pollCategoryData));
      if (pollCategorySaveResult) {
        navigate("/poll");
      }
    }
  };

  const isEdit = form.id ? true : false;

  const checkValidation = (name) => {
    const count = form[name] ? form[name].length : 0;
    if (validation[name] && count > validation[name]) {
      return true;
    } else {
      return false;
    }
  };

  const getHelperText = (name) => {
    const result = checkValidation(name);
    if (result) {
      return `Przekroczono limit ${validation[name]} znaków`;
    } else {
      return "";
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.section}>
        <h2>{header}</h2>
        <form onSubmit={submitHandler}>
          <div className={classes.row}>
            <label className={classes.caption}>Typ zestawu</label>
            <ToggleButtonGroup value={form.poll_type} exclusive onChange={handleFormChange} aria-label="poll type">
              <ToggleButton name="poll_type" value="quiz" sx={{ width: "50%" }} disabled={isEdit}>
                Quiz
              </ToggleButton>
              <ToggleButton name="poll_type" value="survey" sx={{ width: "50%" }} disabled={isEdit}>
                Sonda
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={classes.row}>
            <TextField
              id="name"
              name="name"
              error={checkValidation("name")}
              helperText={getHelperText("name")}
              required
              label="Nazwa"
              variant="outlined"
              onChange={handleFormChange}
              value={form.name}
            />
          </div>
          <div className={classes.row}>
            <DatePicker
              mask="__.__.____"
              label="Data utworzenia"
              value={form.creation_date}
              onChange={(event) => handleDatePickerChange("creation_date", event)}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          {form.poll_type === "survey" && (
            <div className={classes.row}>
              <DatePicker
                mask="__.__.____"
                label="Data zakończenia"
                value={form.expiration_date}
                clearable={true}
                clearText="Wyczyść"
                onChange={(event) => handleDatePickerChange("expiration_date", event)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          )}
          <div className={`${classes.row} ${classes.imageRow}`}>
            {selectedImage && (
              <div className={classes.imageDetails}>
                <Tooltip title={selectedImage}>
                  <img src={imageRootUrl + selectedImage} alt="Preview" className={classes.imagePreview} onClick={handleModalChange} />
                </Tooltip>
              </div>
            )}
            <ImageModal
              isOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setSelectedImage={setSelectedImage}
              setSelectedImageID={setSelectedImageID}
            />
            {!selectedImage && <Button onClick={handleModalChange}>Wybierz zdjęcie *</Button>}
          </div>
          {captions &&
            captions.map((row, index) => {
              return (
                <div key={`captions_${index}`} className={classes.row}>
                  <TextField
                    id={`caption_${index}`}
                    name={`${row.name}_${index}`}
                    required
                    label={getCaptionLabel(row.name)}
                    variant="outlined"
                    onChange={handleCaptionsChange}
                    value={captions[index].text}
                  />
                </div>
              );
            })}
          {summary &&
            form.poll_type === "quiz" &&
            summary.map((row, index) => {
              return (
                <div key={`summary_${index}`} className={classes.row}>
                  <TextField
                    id={`summary_${index}`}
                    name={`summary_${index}`}
                    required
                    label={`Ocena ${row.min}-${row.max}`}
                    variant="outlined"
                    onChange={handleSummaryChange}
                    value={summary[index].recomendation}
                  />
                </div>
              );
            })}
          {form.poll_type === "survey" && (
            <div className={classes.row}>
              <FormControl fullWidth>
                <InputLabel id="metric-select-label">Metryczka *</InputLabel>
                <Select
                  labelId="metric-select-label"
                  name="metric_set_id"
                  id="metric-select"
                  value={form.metric_set_id}
                  label="Metryczka *"
                  onChange={handleFormChange}
                >
                  {Object.values(metric).map((met) => (
                    <MenuItem key={met.id} value={met.id}>
                      {met.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {isEdit && statusEditAllowed && (
            <div className={classes.row}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.is_active}
                  label="Status"
                  onChange={handleStatusChange}
                >
                  <MenuItem value="ACTIVE">Aktywny</MenuItem>
                  <MenuItem value="DRAFT">Draft</MenuItem>
                  <MenuItem value="ARCHIVE">Archiwalny</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className={classes.row}>
            <FormControl>
              <InputLabel id="category-label">Kategorie</InputLabel>
              <Select
                labelId="category-label"
                id="category-select"
                multiple
                value={selectedCategory}
                onChange={handleCategoryChange}
                input={<OutlinedInput id="category" label="Kategorie" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={category[value].description} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Object.values(category).map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.row} style={{ marginBottom: 50 }}>
            <Button variant="outlined" type="submit">
              Zapisz
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PollEditSection;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";

import PollDetails from "./PollDetails";
import PollQuestion from "./PollQuestion";
import StatusModal from "./StatusModal";
import DeleteModal from "./DeleteModal";
import classes from "./PollRow.module.css";

import { Chip, Tooltip } from "@mui/material";

import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

const IMG_URL = "https://quizyrmf.test-site.net.pl";

const statusMap = {
  ACTIVE: "AKCEPT",
  DRAFT: "DRAFT",
};

const colorMap = {
  ACTIVE: "success",
  DRAFT: "warning",
};

const PollRow = ({ poll }) => {
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const profileData = useSelector((state) => state.auth.profileData);

  let statusEditAllowed = false;
  if (profileData && profileData.privileges.includes(144)) {
    statusEditAllowed = true;
  }

  const description = JSON.parse(poll.captions).filter((x) => x.name === "banner_title")[0].text;

  const detailsHandler = () => {
    setShowDetails(!showDetails);
  };

  const showQuestionHandler = () => {
    setShowQuestion(!showQuestion);
  };

  const handlePollEdit = () => {
    navigate(`/poll-edit/?type=${poll.poll_type}&id=${poll.id}&mode=edit`);
  };

  const handlePollClone = () => {
    navigate(`/poll-edit/?type=${poll.poll_type}&id=${poll.id}&mode=clone`);
  };

  const handleChangeActive = () => {
    if (!statusEditAllowed) return;
    setIsStatusModalOpen(true);
  };

  const handleDeleteModal = () => {
    if (!statusEditAllowed) return;
    setIsDeleteModalOpen(true);
  };

  const time = moment(poll.add_time).format("DD.MM.YYYY");
  return (
    <div className={classes.section}>
      <div className={classes.wrapper}>
        {!poll.image_url && (
          <div className={classes.imageTemplate}>
            <ImageNotSupportedIcon />
          </div>
        )}
        {poll.image_url && <div className={classes.imageWrapper} style={{ backgroundImage: "url(" + IMG_URL + poll.image_url + ")" }}></div>}
        <div className={classes.contentRow}>
          <div className={classes.contentColumn}>
            <div className={classes.header}>
              <span className={classes.headerID}>[{poll.id}]</span> {poll.name}
            </div>
            <div className={classes.description}>{description}</div>
            <div className={classes.buttons}>
              <span onClick={detailsHandler}>Szczegóły</span>
              <span onClick={showQuestionHandler}>Pytania</span>
            </div>
          </div>
          <div className={classes.lastColumn}>
            <div>{time}</div>
            {statusEditAllowed && (
              <div>
                <Tooltip title={poll.is_active === "ACTIVE" ? "Zmień status na draft" : "Zmień status na zaakceptowane"}>
                  <Chip
                    label={statusMap[poll.is_active]}
                    color={colorMap[poll.is_active]}
                    size="small"
                    variant="outlined"
                    onClick={handleChangeActive}
                  />
                </Tooltip>
              </div>
            )}
            {!statusEditAllowed && (
              <div>
                <Chip label={statusMap[poll.is_active]} color={colorMap[poll.is_active]} size="small" variant="outlined" />
              </div>
            )}
            <div className={classes.buttonRow}>
              <div className={classes.editButton} onClick={handleDeleteModal}>
                Usuń
              </div>
              <div className={classes.editButton} onClick={handlePollClone}>
                Klonuj
              </div>
              <div className={classes.editButton} onClick={handlePollEdit}>
                Edytuj
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDetails && <PollDetails poll={poll} />}
      {showQuestion && <PollQuestion poll={poll} />}
      {statusEditAllowed && <StatusModal isOpen={isStatusModalOpen} setIsStatusModalOpen={setIsStatusModalOpen} poll={poll} />}
      {statusEditAllowed && <DeleteModal isOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} poll={poll} />}
    </div>
  );
};

export default PollRow;

import { useContext } from "react";
import classes from "./StartingPageContent.module.css";
import AuthContext from "../../store/auth-context";

const StartingPageContent = () => {
  const authCtx = useContext(AuthContext);

  let message = "";

  if (authCtx.profileData) {
    if (authCtx.profileData.FirstName) {
      message = `Witaj ${authCtx.profileData.FirstName}!`;
    } else {
      message = "Witaj!";
    }
  }

  return (
    <section className={classes.starting}>
      <div className={classes.headerRow}>
        <h1>{message}</h1>
      </div>
    </section>
  );
};

export default StartingPageContent;

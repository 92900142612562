import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchQuestionData } from "../../store/actions/question";
import classes from "./PollQuestion.module.css";
import QuestionRow from "./QuestionRow";

const PollQuestion = ({ poll }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question.question[poll.id]);

  useEffect(() => {
    dispatch(fetchQuestionData({ poll_id: poll.id }));
  }, [poll, dispatch]);

  const handleCreateQuestion = () => {
    navigate(`/question-edit/?poll_id=${poll.id}`);
  };

  return (
    <div className={classes.wrapper}>
      <table className={classes.table}>
        <tbody>
          {question &&
            question.map((quest, i) => {
              return <QuestionRow key={i} question={quest} poll_id={poll.id} />;
            })}
          <tr className={classes.newQuestionRow}>
            <td></td>
            <td onClick={handleCreateQuestion}>Nowe pytanie</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PollQuestion;

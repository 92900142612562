import { useContext } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthContext from "../../store/auth-context";
import { config } from "../../store/config";

import { authActions } from "../../store/slices/auth";

import classes from "./MainNavigation.module.css";
import CachedIcon from "@mui/icons-material/Cached";

const MainNavigation = () => {
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
  };

  const handleReload = () => {
    dispatch(authActions.setForceReload(true));
  };

  const logo = `cms4m${config.IS_DEV ? " - dev" : ""}`;

  return (
    <header className={classes.header}>
      <Link to="/">
        <div className={classes.logo}>{logo}</div>
      </Link>
      {isLoggedIn && (
        <div className={classes.refresh}>
          <CachedIcon className={classes.refreshButton} onClick={handleReload} />
        </div>
      )}
      <nav>
        <ul>
          {!isLoggedIn && (
            <li>
              <Link to="/auth">Zaloguj</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/gallery">Galeria</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/article">Artykuły</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/category">Kategorie</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/poll">Zestawy</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={logoutHandler}>Wyloguj</button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;

import { createSlice } from "@reduxjs/toolkit";

const metricSlice = createSlice({
  name: "metric",
  initialState: {
    metric: {},
  },
  reducers: {
    setMetric(state, action) {
      state.metric = action.payload;
    },
  },
});

export const metricActions = metricSlice.actions;

export default metricSlice.reducer;

import classes from "./PollDetails.module.css";

import { useSelector } from "react-redux";

const PollRow = ({ poll }) => {
  const captionsDict = {
    banner_title: "Baner",
    title: "Tytuł",
    subtitle: "Podtytuł",
    description: "Opis",
    call2action: "Call2action",
    ads_title: "Tytuł wyświetlany na reklamach",
  };

  const pollCategory = useSelector((state) => state.pollCategory.pollCategory);
  const category = useSelector((state) => state.category.category);

  const categories = pollCategory[poll.id] ? pollCategory[poll.id].map((x) => category[x].description) : ["-"];

  const captionsArray = JSON.parse(poll.captions);
  const summaryArray = JSON.parse(poll.summary);

  return (
    <div className={classes.wrapper}>
      {captionsArray &&
        captionsArray.map((cap, i) => {
          return (
            <div className={classes.row} key={i}>
              <div className={classes.caption}>{captionsDict[cap.name]}</div>
              <div className={classes.content}>{cap.text}</div>
            </div>
          );
        })}
      {summaryArray &&
        summaryArray.map((cap, i) => {
          return (
            <div className={classes.row} key={i}>
              <div className={classes.caption}>{`Ocena ${cap.min}-${cap.max}`}</div>
              <div className={classes.content}>{cap.recomendation}</div>
            </div>
          );
        })}
      <div className={classes.row}>
        <div className={classes.caption}>Kategorie</div>
        <div className={classes.content}>{categories.join(", ")}</div>
      </div>
    </div>
  );
};

export default PollRow;

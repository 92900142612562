import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { uiActions } from "../../store/slices/ui";

import classes from "./UploadImage.module.css";

import Button from "@mui/material/Button";

import { uploadFile } from "../../store/actions/image";

function UploadImage() {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [imageDetails, setImageDetails] = useState([]);

  useEffect(() => {
    if (!selectedFiles) {
      setImagePreview(undefined);
      return;
    }

    let objectUrl;

    const prepareImageData = async () => {
      const imageDetailsArray = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        objectUrl = URL.createObjectURL(selectedFiles[i]);
        if (selectedFiles.length <= 1) {
          setImagePreview(objectUrl);
        } else {
          setImagePreview();
        }

        const newImg = new Image();
        newImg.src = objectUrl;

        await newImg.decode();

        const imgObj = {
          width: newImg.width,
          height: newImg.height,
          size: selectedFiles[i].size,
          type: selectedFiles[i].type,
          name: selectedFiles[i].name,
        };

        imageDetailsArray.push(imgObj);
      }
      setImageDetails(imageDetailsArray);
    };

    prepareImageData();

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFiles]);

  const imageChangeHandler = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFiles(undefined);
      setIsFilePicked(false);
      return;
    }

    setSelectedFiles(event.target.files);
    setIsFilePicked(true);
  };

  const handleImageClear = () => {
    setIsFilePicked(false);
    setSelectedFiles();
    setImagePreview();
    setImageDetails([]);
    document.querySelector("#raised-button-file").value = "";
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (isFilePicked) {
      let formData = new FormData();

      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append(selectedFiles[i].name, selectedFiles[i]);

        if (imageDetails[i].size > 204800) {
          return dispatch(
            uiActions.showNotification({
              status: "error",
              title: "Błąd!",
              message: "Rozmiar pliku przekracza dopuszczalną wielkość!",
            })
          );
        }
      }

      const uploadResult = await dispatch(uploadFile(formData));
      if (uploadResult) {
        handleImageClear();
      }
    }
  };

  const getSize = (size) => {
    return Math.round((size / 1024) * 100) / 100;
  };

  return (
    <div className={classes.centerRow}>
      {isFilePicked && (
        <div className={classes.imageDetails}>
          {imagePreview && (
            <label htmlFor="raised-button-file" className={classes.label}>
              <img src={imagePreview} alt="Preview" className={classes.imagePreview} />
            </label>
          )}
          {imagePreview &&
            imageDetails.map((image) => (
              <div key={image.name} className={classes.caption}>
                <p>{image.name}</p>
                <p>
                  {image.width}px / {image.height}px
                </p>
                <p style={{ color: image.size > 204800 ? "rgba(255, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.7)" }}>{getSize(image.size)} kB</p>
                <p>{image.type}</p>
              </div>
            ))}
        </div>
      )}
      {isFilePicked && !imagePreview && (
        <table className={classes.detailsTable}>
          <thead>
            <tr>
              <th>Nazwa</th>
              <th width={150}>Rozdzielczość</th>
              <th width={120}>Rozmiar</th>
              <th width={100}>Typ</th>
            </tr>
          </thead>
          <tbody>
            {imageDetails.map((img, index) => (
              <tr key={index}>
                <td>{img.name}</td>
                <td>
                  {img.width}px / {img.height}px
                </td>
                <td style={{ color: img.size > 204800 ? "rgba(255, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.7)" }}>{getSize(img.size)} kB</td>
                <td>{img.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <input
        accept="image/*"
        multiple
        className={classes.input}
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={imageChangeHandler}
      />
      {!isFilePicked && (
        <label htmlFor="raised-button-file">
          <Button variant="outlined" component="span" className={classes.button}>
            Dodaj zdjęcie
          </Button>
        </label>
      )}
      {isFilePicked && (
        <label>
          <Button variant="outlined" component="span" className={classes.button} color="warning" sx={{ mr: 1 }} onClick={handleImageClear}>
            Anuluj
          </Button>
          <Button variant="outlined" component="span" className={classes.button} color="success" sx={{ ml: 1 }} onClick={submitHandler}>
            Zapisz
          </Button>
        </label>
      )}
    </div>
  );
}

export default UploadImage;

import { useState, useEffect } from "react";
import useMountEffect from "../../hooks/useMountEffect";
import classes from "./ArticleSection.module.css";

import ArticleRow from "./ArticleRow";

import { useSelector, useDispatch } from "react-redux";

import { Pagination } from "@mui/material";

import { fetchPollData } from "../../store/actions/poll";
import { fetchArticleData } from "../../store/actions/article";
import { fetchPollArticleData } from "../../store/actions/poll_article";
import { authActions } from "../../store/slices/auth";

const ArticleSection = () => {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.poll.survey);
  const article = useSelector((state) => state.article.article);
  const articlePoll = useSelector((state) => state.pollArticle.articlePoll);
  const forceReload = useSelector((state) => state.auth.forceReload);
  const isPollFiltered = useSelector((state) => state.poll.filtered);
  const articleCounter = useSelector((state) => state.article.counter);

  const [filters, setFilters] = useState({ page: 1 });

  const loadData = (force) => {
    if (force || (article && Object.keys(article).length <= 0)) {
      dispatch(fetchArticleData(filters));
    }

    if (force || (articlePoll && Object.keys(articlePoll).length <= 0)) {
      dispatch(fetchPollArticleData());
    }

    if (force || isPollFiltered || (survey && survey.length <= 0)) {
      dispatch(fetchPollData({}));
    }
  };

  useMountEffect(() => {
    loadData(false);
  });

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  });

  const handlePageChange = (event, page) => {
    handleArticleChange([{ type: "page", value: page }]);
  };

  const handleArticleChange = (filterArray) => {
    let newFilters = JSON.parse(JSON.stringify(filters));
    filterArray.forEach((param) => {
      newFilters[param.type] = param.value;
    });
    setFilters(newFilters);
    reloadArticleData(newFilters);
  };

  const reloadArticleData = (newFilters) => {
    dispatch(fetchArticleData(newFilters));
  };

  const paginationCount = articleCounter ? Math.ceil(articleCounter / 10) : 1;

  return (
    <section className={classes.starting}>
      <div className={classes.headerRow}>
        <h2 className={classes.pollHeader}>Artykuły</h2>
      </div>
      <div className={classes.table}>
        <div className={classes.pollSection}>
          {article &&
            Object.values(article)
              .sort((a, b) => b.id - a.id)
              .map((x) => {
                return <ArticleRow article={x} key={x.id} />;
              })}
          {article.length < 1 && <div style={{ margin: "10px 0" }}>Brak danych</div>}
        </div>
        <div className={classes.paginationRow}>
          <Pagination count={paginationCount} page={filters.page} onChange={handlePageChange} />
        </div>
      </div>
    </section>
  );
};

export default ArticleSection;

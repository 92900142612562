import { uiActions } from "../slices/ui";
import { pollActions } from "../slices/poll";

import { sendRequest } from "./auth";

export const fetchPollData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const result = await dispatch(sendRequest("poll", filters));

      let filtered = false;
      if (filters.poll_type) {
        filtered = true;
      }
      if (filters.search && filters.search !== "") {
        filtered = true;
      }
      if (filters.page) {
        filtered = true;
      }
      if (filters.status && filters.status !== "") {
        filtered = true;
      }
      if (filtered) {
        dispatch(pollActions.setFilters(filters));
      } else {
        dispatch(pollActions.setFilters({}));
      }
      dispatch(pollActions.setFiltered(filtered));

      const pollData = result.data;
      const counter = result.count;

      let quiz = [];
      let survey = [];

      if (pollData) {
        quiz = JSON.parse(JSON.stringify(pollData)).filter((x) => x.poll_type === "quiz");
        survey = JSON.parse(JSON.stringify(pollData)).filter((x) => x.poll_type === "survey");
      }

      dispatch(pollActions.setPoll(pollData));
      dispatch(pollActions.setQuiz(quiz));
      dispatch(pollActions.setSurvey(survey));
      dispatch(pollActions.setCounter(counter));

      let sortedQuiz = JSON.parse(JSON.stringify(quiz)).sort((a, b) => (a.name > b.name && 1) || -1);
      let sortedSurvey = JSON.parse(JSON.stringify(survey)).sort((a, b) => (a.name > b.name && 1) || -1);

      dispatch(pollActions.setSortedQuiz(sortedQuiz));
      dispatch(pollActions.setSortedSurvey(sortedSurvey));
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Błąd!",
          message: "Błąd wczytywania danych!",
        })
      );
      return err;
    }
    return Promise.resolve();
  };

export const savePollData = (record, pollFilters) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("poll", record, "PUT"));
    console.log(result);

    if (result) {
      fnResult = result;
      await dispatch(fetchPollData(pollFilters));

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    let message = "Błąd zapisu danych!";

    if (err.code === 405) {
      message = "Osiągnięto minimalną ilość dla kategorii!";
    }
    if (err.code === 409) {
      message = "Podana nazwa już istnieje, podaj inną!";
    }
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: message,
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const saveActiveStatus = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("poll/status", record, "PUT"));

    if (result) {
      fnResult = result;
      await dispatch(fetchPollData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Status zmieniony!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    let message = "Błąd zapisu danych!";

    if (err.code === 405) {
      message = "Osiągnięto minimalną ilość dla kategorii!";
    }

    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: message,
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const deletePollData = (record, pollFilters) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("poll", record, "DELETE"));

    if (result) {
      fnResult = result;
      await dispatch(fetchPollData(pollFilters));

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord usunięty!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    let message = "Błąd zapisu danych!";

    if (err.code === 405) {
      message = "Osiągnięto minimalną ilość dla kategorii!";
    }
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: message,
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

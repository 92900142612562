import { uiActions } from "../slices/ui";
import { authActions } from "../slices/auth";
import { config } from "../config";

const API_URL = `https://api-utils4.mobi-me.pl/${config.IS_DEV ? "cms_dev" : "cms"}/`;

export const sendRequest =
  (endpoint, body = {}, method = "POST") =>
  async (dispatch) => {
    let result = true;

    const requestMethod = async () => {
      const token = localStorage.getItem("token");

      body = JSON.parse(JSON.stringify(body));
      body = Object.assign(body, { IS_DEV: config.IS_DEV });
      const requestBody =
        method === "POST"
          ? JSON.stringify({ filters: body, uiVersion: config.version })
          : JSON.stringify({ record: body, uiVersion: config.version });

      const response = await fetch(`${API_URL}${endpoint}${!!token ? "/?token=" + token : ""}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      if (!response.ok) {
        if (response.status === 412) {
          dispatch(authActions.setIsIncorrectVersion(true));
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Nowa wersja",
              message: "Nowa wersja - przeładuj stronę!",
            })
          );
          return [];
        } else {
          console.log(response);
          const error = new Error("Request error!");
          error.code = response.status;
          throw error;
        }
      }

      const data = await response.json();

      return data.data;
    };

    result = await requestMethod();
    return Promise.resolve(result);
  };

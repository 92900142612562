import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import classes from "./ImageModal.module.css";

import { useSelector } from "react-redux";

const BASE_URL = "https://quizyrmf.test-site.net.pl";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  paddingLeft: "15px",
};

const ImageModal = (params) => {
  const handleClose = () => params.setIsModalOpen(false);

  let imageData = useSelector((state) => state.image.image);
  if (params.limit) {
    imageData = imageData.filter((x) => x.image_width < params.limit);
  }

  const handleImageClick = (e) => {
    params.setSelectedImageID(e.id);
    params.setSelectedImage(e.image_url);
    params.setIsModalOpen(false);
  };

  const clearImage = () => {
    params.setSelectedImageID();
    params.setSelectedImage();
    params.setIsModalOpen(false);
  };

  return (
    <div>
      <Modal open={params.isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
            {imageData.map((item) => (
              <ImageListItem key={item.image_url} className={classes.imagePiece} onClick={() => handleImageClick(item)}>
                <img
                  src={`${BASE_URL}${item.image_url}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${BASE_URL}${item.image_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.image_name}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
          <div className={classes.centerRow}>
            {params.clearPermitted && <Button onClick={clearImage}>Wyczyść</Button>}
            <Button onClick={handleClose}>Anuluj</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ImageModal;

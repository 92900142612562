import React from "react";

import { useNavigate } from "react-router-dom";

import classes from "./ExistingQuestions.module.css";

function ExistingQuestions({ questions, poll_id }) {
  const navigate = useNavigate();

  const handleQuestionEdit = (question_id) => {
    navigate(`/question-edit/?id=${question_id}&poll_id=${poll_id}`);
  };

  const handleQuestionCreate = () => {
    navigate(`/question-edit/?poll_id=${poll_id}`);
  };

  const handleAnswerEdit = (question_id) => {
    navigate(`/answer-edit/?question_id=${question_id}&poll_id=${poll_id}`);
  };

  return (
    <div className={classes.wrapper}>
      <h3>Pytania</h3>
      {questions && (
        <table className={classes.table}>
          <tbody>
            {questions.map((q) => {
              return (
                <tr key={q.id}>
                  <td width="30">{`${q.position})`}</td>
                  <td>{q.content}</td>
                  <td width="60" className={classes.editButton} onClick={() => handleQuestionEdit(q.id)}>
                    Edytuj
                  </td>
                  <td width="120" className={classes.editButton} onClick={() => handleAnswerEdit(q.id)}>
                    {`Odpowiedzi (${q.answers})`}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td width="30"></td>
              <td className={classes.editButton} onClick={handleQuestionCreate}>
                Nowe pytanie
              </td>
              <td width="60"></td>
              <td width="120"></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ExistingQuestions;

import { uiActions } from "../slices/ui";
import { pollCategoryActions } from "../slices/poll_category";
import { sendRequest } from "./auth";

export const fetchPollCategoryData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const pollCategoryData = await dispatch(sendRequest("pollCategory", filters));

      dispatch(pollCategoryActions.setPollCategory(pollCategoryData.pollCategory));
      dispatch(pollCategoryActions.setCategoryPoll(pollCategoryData.categoryPoll));
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Loading data failed!",
        })
      );
    }
    return Promise.resolve();
  };

export const savePollCategoryData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("pollCategory", record, "PUT"));

    if (result) {
      await dispatch(fetchPollCategoryData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const addPollCategoryData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("pollCategory/add", record, "PUT"));

    if (result) {
      await dispatch(fetchPollCategoryData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const deletePollCategoryData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("pollCategory/delete", record, "PUT"));

    if (result) {
      await dispatch(fetchPollCategoryData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

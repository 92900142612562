import { createSlice } from "@reduxjs/toolkit";

const pollSlice = createSlice({
  name: "poll",
  initialState: {
    survey: [],
    quiz: [],
    poll: [],
    sortedQuiz: [],
    sortedSurvey: [],
    filtered: false,
    filters: {},
    counter: 0,
  },
  reducers: {
    setSurvey(state, action) {
      state.survey = action.payload;
    },
    setQuiz(state, action) {
      state.quiz = action.payload;
    },
    setPoll(state, action) {
      state.poll = action.payload;
    },
    setSortedQuiz(state, action) {
      state.sortedQuiz = action.payload;
    },
    setSortedSurvey(state, action) {
      state.sortedSurvey = action.payload;
    },
    setFiltered(state, action) {
      state.filtered = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setCounter(state, action) {
      state.counter = action.payload;
    },
  },
});

export const pollActions = pollSlice.actions;

export default pollSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const pollArticleSlice = createSlice({
  name: "pollArticle",
  initialState: {
    pollArticle: {},
    articlePoll: {},
  },
  reducers: {
    setPollArticle(state, action) {
      state.pollArticle = action.payload;
    },
    setArticlePoll(state, action) {
      state.articlePoll = action.payload;
    },
  },
});

export const pollArticleActions = pollArticleSlice.actions;

export default pollArticleSlice.reducer;

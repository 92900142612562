import { uiActions } from "../slices/ui";
import { articleActions } from "../slices/article";

import { sendRequest } from "./auth";

export const fetchArticleData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const articleData = await dispatch(sendRequest("article", filters));

      dispatch(articleActions.setArticle(articleData.data));
      dispatch(articleActions.setCounter(articleData.count));
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Loading data failed!",
        })
      );
    }
    return Promise.resolve();
  };

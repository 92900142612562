import React from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { deleteAnswerData } from "../../store/actions/answer";

import classes from "./ExistingAnswers.module.css";

function ExistingAnswers({ answers, question_id, poll_id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAnswerEdit = (answer_id) => {
    navigate(`/answer-edit/?id=${answer_id}&question_id=${question_id}&poll_id=${poll_id}`);
  };

  const handleAnswerDelete = async (answer_id) => {
    await dispatch(deleteAnswerData({ answer_id, question_id: +question_id, poll_id: +poll_id }));
  };

  const handleAnswerCreate = () => {
    navigate(`/answer-edit/?question_id=${question_id}&poll_id=${poll_id}`);
  };

  const answerClass = (answer) => {
    if (answer.is_correct) {
      return classes.correctAnswer;
    } else {
      return "";
    }
  };

  return (
    <div className={classes.wrapper}>
      <h3>Odpowiedzi</h3>
      {answers && (
        <table className={classes.table}>
          <tbody>
            {answers.map((a) => {
              return (
                <tr key={a.id}>
                  <td width="30">{`${a.position})`}</td>
                  <td className={answerClass(a)}>{a.content}</td>
                  <td width="80" className={classes.editButton} onClick={() => handleAnswerEdit(a.id)}>
                    Edytuj
                  </td>
                  <td width="80" className={classes.editButton} onClick={() => handleAnswerDelete(a.id)}>
                    Usuń
                  </td>
                </tr>
              );
            })}
            <tr>
              <td width="30"></td>
              <td className={classes.editButton} onClick={handleAnswerCreate}>
                Nowa odpowiedź
              </td>
              <td width="80"></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ExistingAnswers;

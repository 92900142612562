import { configureStore } from "@reduxjs/toolkit";

import uiSlice from "./slices/ui";
import pollSlice from "./slices/poll";
import authSlice from "./slices/auth";
import questionSlice from "./slices/question";
import answerSlice from "./slices/answer";
import categorySlice from "./slices/category";
import pollCategorySlice from "./slices/poll_category";
import imageSlice from "./slices/image";
import articleSlice from "./slices/article";
import pollArticleSlice from "./slices/poll_article";
import metricSlice from "./slices/metric";

const store = configureStore({
  reducer: {
    ui: uiSlice,
    poll: pollSlice,
    auth: authSlice,
    question: questionSlice,
    answer: answerSlice,
    category: categorySlice,
    pollCategory: pollCategorySlice,
    image: imageSlice,
    article: articleSlice,
    pollArticle: pollArticleSlice,
    metric: metricSlice,
  },
});

export default store;

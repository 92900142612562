import React, { useState, useEffect } from "react";

import { TextField, Tooltip } from "@mui/material";

function PollSearchInput(params) {
  const [searchValue, setSearchValue] = useState("");
  const [searchTimer, setSearchTimer] = useState(0);

  const debounce = (cb, delay) => {
    clearTimeout(searchTimer);
    setSearchTimer(setTimeout(cb, delay));
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    debounce(() => {
      params.setSearchValue(value);
      params.handlePollChange([
        { type: "search", value },
        { type: "page", value: 1 },
      ]);
    }, 1000);
  };

  const clearSearchValue = () => {
    setSearchValue("");
  };

  useEffect(() => {
    params.childFunc.current = clearSearchValue;
  }, [params.childFunc]);

  return (
    <div>
      <Tooltip title="Wyszukaj po nazwie lub id" placement="top">
        <TextField id="searchBar" name="searchBar" label="Szukaj" variant="outlined" size="small" onChange={handleSearch} value={searchValue} />
      </Tooltip>
    </div>
  );
}

export default PollSearchInput;

import AnswerEdit from "../components/AnswerEdit/AnswerEdit";
import useQuery from "../hooks/useQuery";

const HomePage = () => {
  const query = useQuery();

  return <AnswerEdit id={query.get("id")} question_id={query.get("question_id")} poll_id={query.get("poll_id")} />;
};

export default HomePage;

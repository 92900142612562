import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import classes from "./DetailsModal.module.css";

import { useDispatch } from "react-redux";

import { saveActiveStatus } from "../../store/actions/image";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "15px",
  width: "400px",
};

const DetailsModal = (params) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    params.setSelectedImage();
    params.setIsModalOpen(false);
  };

  const handleAccept = async (e) => {
    const saveResult = await dispatch(saveActiveStatus({ id: params.image.id, is_active: 0 }));
    if (saveResult) {
      params.setIsModalOpen(false);
    }
  };

  const getSize = (size) => {
    return Math.round((size / 1024) * 100) / 100;
  };

  return (
    <div>
      <Modal open={params.isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {params.image ? params.image.image_name : "Zdjęcie"}
          </Typography>
          {params.image && (
            <img src={`https://quizyrmf.test-site.net.pl${params.image.image_url}`} alt={params.image.image_name} style={{ width: "100%" }} />
          )}
          <Typography sx={{ mt: 2, fontSize: "14px" }}>
            {`Rozdzielczość:  ${params.image ? params.image.image_width + "px / " + params.image.image_height + "px" : "-"}`}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>{`Rozmiar:  ${params.image ? getSize(params.image.image_size) + " kB" : "-"}`}</Typography>
          <Typography sx={{ fontSize: "14px" }}>{`Typ:  ${params.image ? params.image.image_type : "-"}`}</Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Czy chcesz usunąć zdjęcie?
          </Typography>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DetailsModal;

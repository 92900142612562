import { createSlice } from "@reduxjs/toolkit";

const questionSlice = createSlice({
  name: "question",
  initialState: {
    question: {},
    questionsArray: [],
  },
  reducers: {
    setQuestion(state, action) {
      state.question[action.payload.poll_id] = action.payload.question;
    },
    setAllQuestions(state, action) {
      state.questionsArray = action.payload;
    },
  },
});

export const questionActions = questionSlice.actions;

export default questionSlice.reducer;

import { uiActions } from "../slices/ui";
import { metricActions } from "../slices/metric";

import { sendRequest } from "./auth";

export const fetchMetricData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const metricData = await dispatch(sendRequest("metric", filters));

      dispatch(metricActions.setMetric(metricData));
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Loading data failed!",
        })
      );
    }
    return Promise.resolve();
  };

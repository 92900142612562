import { createSlice } from "@reduxjs/toolkit";

const articleSlice = createSlice({
  name: "article",
  initialState: {
    article: [],
    counter: 0,
  },
  reducers: {
    setArticle(state, action) {
      state.article = action.payload;
    },
    setCounter(state, action) {
      state.counter = action.payload;
    },
  },
});

export const articleActions = articleSlice.actions;

export default articleSlice.reducer;

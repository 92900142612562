import { uiActions } from "../slices/ui";
import { answerActions } from "../slices/answer";

import { sendRequest } from "./auth";

import { fetchQuestionData } from "./question";

export const fetchAnswerData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const answerData = await dispatch(sendRequest("answer", filters));

      if (filters && filters.question_id) {
        dispatch(answerActions.setAnswer({ question_id: filters.question_id, answer: answerData }));
      } else {
        dispatch(answerActions.setAnswersArray(answerData));
      }
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Loading data failed!",
        })
      );
    }
    return Promise.resolve();
  };

export const saveAnswerData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("answer", record, "PUT"));

    if (result) {
      dispatch(fetchQuestionData({ poll_id: record.poll_id }));
      await dispatch(fetchAnswerData({ question_id: record.question_id }));

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const deleteAnswerData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("answer", record, "DELETE"));

    if (result) {
      dispatch(fetchQuestionData({ poll_id: record.poll_id }));
      await dispatch(fetchAnswerData({ question_id: record.question_id }));

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord usunięty!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

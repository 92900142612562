import { useState, useEffect, useRef } from "react";
import useMountEffect from "../../hooks/useMountEffect";
import { useNavigate } from "react-router-dom";
import classes from "./PollSection.module.css";

import PollRow from "./PollRow";
import PollSearchInput from "./PollSearchInput";

import { useSelector, useDispatch } from "react-redux";

import { ToggleButtonGroup, ToggleButton, Pagination, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";

import { fetchPollData } from "../../store/actions/poll";
import { fetchCategoryData } from "../../store/actions/category";
import { fetchMetricData } from "../../store/actions/metric";
import { fetchPollCategoryData } from "../../store/actions/poll_category";
import { fetchImageData } from "../../store/actions/image";
import { authActions } from "../../store/slices/auth";

const PollSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quiz = useSelector((state) => state.poll.quiz);
  const survey = useSelector((state) => state.poll.survey);
  const poll = useSelector((state) => state.poll.poll);
  const category = useSelector((state) => state.category.category);
  const metric = useSelector((state) => state.metric.metric);
  const pollCategory = useSelector((state) => state.pollCategory.pollCategory);
  const imageData = useSelector((state) => state.image.image);
  const forceReload = useSelector((state) => state.auth.forceReload);
  const isPollFiltered = useSelector((state) => state.poll.filtered);
  const pollFilters = useSelector((state) => state.poll.filters);
  const pollCounter = useSelector((state) => state.poll.counter);

  const [poll_type, setPollType] = useState("quiz");
  const [selectedStatus, setSelectedStatus] = useState("");
  // eslint-disable-next-line
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({ page: 1, search: "", poll_type: "quiz", status: "" });

  const childFunc = useRef(null);

  const loadData = (force, mountPollFilters) => {
    if (force || (imageData && imageData.length <= 0)) {
      dispatch(fetchImageData());
    }

    if (force || (category && Object.keys(category).length <= 0)) {
      dispatch(fetchCategoryData());
    }

    if (force || (pollCategory && Object.keys(pollCategory).length <= 0)) {
      dispatch(fetchPollCategoryData());
    }

    if (force || (metric && Object.keys(metric).length <= 0)) {
      dispatch(fetchMetricData());
    }

    if (force || !isPollFiltered || (poll && poll.length <= 0)) {
      let actualPollFilters = mountPollFilters ? mountPollFilters : filters;
      dispatch(fetchPollData(actualPollFilters));
    }
  };

  useMountEffect(() => {
    let mountPollFilters = filters;
    if (isPollFiltered) {
      mountPollFilters = pollFilters;
      setPollType(pollFilters.poll_type);
      setSelectedStatus(pollFilters.status);
      setFilters(pollFilters);
    }
    loadData(false, mountPollFilters);
  });

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  });

  const handlePollTypeChange = (event, pollType) => {
    if (pollType !== null) {
      setPollType(pollType);
      childFunc.current();
      setSelectedStatus("");
      handlePollChange([
        { type: "poll_type", value: pollType },
        { type: "page", value: 1 },
        { type: "search", value: "" },
        { type: "status", value: "" },
      ]);
    }
  };

  const handlePageChange = (event, page) => {
    handlePollChange([{ type: "page", value: page }]);
  };

  const handleStatusSelect = async (event) => {
    setSelectedStatus(event.target.value);
    handlePollChange([
      { type: "status", value: event.target.value },
      { type: "page", value: 1 },
    ]);
  };

  const handlePollChange = (filterArray) => {
    let newFilters = JSON.parse(JSON.stringify(filters));
    filterArray.forEach((param) => {
      newFilters[param.type] = param.value;
    });
    setFilters(newFilters);
    reloadPollData(newFilters);
  };

  const reloadPollData = (newFilters) => {
    dispatch(fetchPollData(newFilters));
  };

  const handlePollCreate = (poll_type) => {
    navigate(`/poll-edit/?type=${poll_type}`);
  };

  const paginationCount = pollCounter ? Math.ceil(pollCounter / 10) : 1;

  return (
    <section className={classes.starting}>
      <div className={classes.headerRow}>
        <h2 className={classes.pollHeader}>Zestawy</h2>
      </div>
      <div className={classes.actionRow}>
        <div className={classes.filtersSection}>
          <PollSearchInput setSearchValue={setSearchValue} handlePollChange={handlePollChange} childFunc={childFunc} />
          <FormControl size="small" sx={{ width: "140px", marginLeft: "10px" }}>
            <InputLabel id="poll-section-select-label">Filtruj</InputLabel>
            <Select id="poll-section-select" label="Filtruj" onChange={handleStatusSelect} value={selectedStatus}>
              <MenuItem value="">-</MenuItem>
              <MenuItem value="PROMOTED">Promowane</MenuItem>
              <MenuItem value="DRAFT">Draft</MenuItem>
              <MenuItem value="ACTIVE">Zaakceptowane</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.createButton} onClick={() => handlePollCreate(poll_type)}>
          <Button>Dodaj</Button>
        </div>
      </div>
      <div className={classes.toggleGroup}>
        <ToggleButtonGroup fullWidth color="primary" value={poll_type} exclusive onChange={handlePollTypeChange}>
          <ToggleButton value="quiz">Quiz</ToggleButton>
          <ToggleButton value="survey">Sonda</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.table}>
        {poll_type === "quiz" && (
          <div className={classes.pollSection}>
            {quiz &&
              quiz.map((x) => {
                return <PollRow poll={x} key={x.id} />;
              })}
            {quiz.length < 1 && <div style={{ margin: "10px 0" }}>Brak danych</div>}
          </div>
        )}
        {poll_type === "survey" && (
          <div className={classes.pollSection}>
            {survey &&
              survey.map((x) => {
                return <PollRow poll={x} key={x.id} />;
              })}
            {survey.length < 1 && <div style={{ margin: "10px 0" }}>Brak danych</div>}
          </div>
        )}
        <div className={classes.paginationRow}>
          <Pagination count={paginationCount} page={filters.page} onChange={handlePageChange} />
        </div>
      </div>
    </section>
  );
};

export default PollSection;

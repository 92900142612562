import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchAnswerData } from "../../store/actions/answer";
import classes from "./PollAnswer.module.css";

import EditIcon from "@mui/icons-material/Edit";
import { Avatar } from "@mui/material";

const imageRootUrl = "https://quizyrmf.test-site.net.pl/";

const PollAnswer = ({ question, poll_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const answer = useSelector((state) => state.answer.answer[question.id]);

  useEffect(() => {
    dispatch(fetchAnswerData({ question_id: question.id }));
  }, [question, dispatch]);

  const answerClass = (answer) => {
    if (answer.is_correct) {
      return classes.correctAnswer;
    } else {
      return "";
    }
  };

  const handleAnswerEdit = (answer_id) => {
    navigate(`/answer-edit/?id=${answer_id}&question_id=${question.id}&poll_id=${poll_id}`);
  };

  const handleAnswerCreate = () => {
    navigate(`/answer-edit/?question_id=${question.id}&poll_id=${poll_id}`);
  };

  return (
    <table className={classes.table}>
      <tbody>
        {answer &&
          answer.map((ans, i) => {
            return (
              <tr className={classes.tableRow} key={i}>
                <td width="30">{`${ans.position}.`}</td>
                <td className={`${answerClass(ans)} ${classes.contentColumn}`}>
                  {ans.image_id && (
                    <Avatar alt={ans.content} src={imageRootUrl + ans.image_url} sx={{ width: 30, height: 30, mr: "10px" }} variant="square" />
                  )}
                  {ans.content}
                </td>
                <td width="40">
                  <EditIcon className={classes.pointerElement} sx={{ fontSize: 16, marginLeft: "10px" }} onClick={() => handleAnswerEdit(ans.id)} />
                </td>
              </tr>
            );
          })}
        <tr className={classes.tableRow}>
          <td width="30"></td>
          <td onClick={handleAnswerCreate} className={classes.newAnswerRow}>
            Nowa odpowiedź
          </td>
          <td width="30"></td>
        </tr>
      </tbody>
    </table>
  );
};

export default PollAnswer;

import { Routes, Route, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AuthContext from "./store/auth-context";

import { uiActions } from "./store/slices/ui";

import Layout from "./components/Layout/Layout";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import PollPage from "./pages/PollPage";
import PollEditPage from "./pages/PollEditPage";
import QuestionEditPage from "./pages/QuestionEditPage";
import AnswerEditPage from "./pages/AnswerEditPage";
import GalleryPage from "./pages/GalleryPage";
import CategoryPage from "./pages/CategoryPage";
import ArticlePage from "./pages/ArticlePage";

import { Alert, Button } from "@mui/material";
import Fade from "@mui/material/Fade";

let isInitial = true;

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isVisible = useSelector((state) => state.ui.isVisible);
  const isIncorrectVersion = useSelector((state) => state.auth.isIncorrectVersion);
  const notification = useSelector((state) => state.ui.notification);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (isInitial) {
      navigate("/");
      isInitial = false;
      return;
    }
  }, [navigate]);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        dispatch(uiActions.toggleNotification());
      }, 3000);
    }
  }, [isVisible, dispatch]);

  if (!authCtx.isLoggedIn)
    return (
      <Layout>
        <AuthPage />
      </Layout>
    );

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <>
      {isIncorrectVersion && (
        <Alert
          severity="warning"
          variant="filled"
          sx={{ borderRadius: 0 }}
          action={
            <Button color="inherit" size="small" sx={{ mr: "20px" }} onClick={reloadPage}>
              Odśwież
            </Button>
          }
        >
          Nieaktualna wersja aplikacji - odśwież stronę
        </Alert>
      )}
      <Layout>
        {isInitial && (
          <Routes>
            <Route path="/" exact element={<HomePage />}></Route>
            <Route path="*" Navigate="/"></Route>
          </Routes>
        )}
        {!isInitial && (
          <Routes>
            <Route path="/" exact element={<HomePage />}></Route>
            <Route path="/category" element={<CategoryPage />}></Route>
            <Route path="/article" element={<ArticlePage />}></Route>
            <Route path="/gallery" element={<GalleryPage />}></Route>
            <Route path="/poll" element={<PollPage />}></Route>
            <Route path="/poll-edit" element={<PollEditPage />}></Route>
            <Route path="/question-edit" element={<QuestionEditPage />}></Route>
            <Route path="/answer-edit" element={<AnswerEditPage />}></Route>
            <Route path="/auth" element={!authCtx.isLoggedIn ? <AuthPage /> : <HomePage />}></Route>
            <Route path="*" Navigate="/"></Route>
          </Routes>
        )}
      </Layout>
      <Fade in={isVisible}>
        <Alert style={{ position: "fixed", bottom: 20, right: 20, minWidth: "300px" }} severity={notification.status} variant="filled">
          {notification.message}
        </Alert>
      </Fade>
    </>
  );
}

export default App;

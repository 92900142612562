import QuestionEditSection from "../components/QuestionEdit/QuestionEdit";
import useQuery from "../hooks/useQuery";

const HomePage = () => {
  const query = useQuery();

  return <QuestionEditSection id={query.get("id")} poll_id={query.get("poll_id")} />;
};

export default HomePage;

import { uiActions } from "../slices/ui";
import { questionActions } from "../slices/question";

import { sendRequest } from "./auth";

export const fetchQuestionData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const questionData = await dispatch(sendRequest("question", filters));

      if (filters && filters.poll_id) {
        dispatch(questionActions.setQuestion({ poll_id: filters.poll_id, question: questionData }));
      } else {
        dispatch(questionActions.setQuestionArray(questionData));
      }
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Loading data failed!",
        })
      );
    }
    return Promise.resolve();
  };

export const saveQuestionData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("question", record, "PUT"));

    if (result) {
      await dispatch(fetchQuestionData({ poll_id: record.poll_id }));

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { TextField, Button } from "@mui/material";

import { saveQuestionData, fetchQuestionData } from "../../store/actions/question";

import ExistingQuestions from "./ExistingQuestions";
import classes from "./QuestionEdit.module.css";

const defaultForm = {
  id: null,
  poll_id: null,
  question_type: "single",
  content: "",
  position: 1,
  is_active: 1,
};

function QuestionEditSection({ id, poll_id }) {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.question.question);
  const poll = useSelector((state) => state.poll.poll);

  const [form, setForm] = useState(JSON.parse(JSON.stringify(defaultForm)));
  const [selectedPoll, setSelectedPoll] = useState({});
  const [header, setHeader] = useState(`Nowe pytanie`);

  useEffect(() => {
    let foundPoll = poll.find((x) => x.id === +poll_id);
    setSelectedPoll(foundPoll);
  }, [poll, poll_id]);

  useEffect(() => {
    if (!questions[poll_id] && poll_id) {
      dispatch(fetchQuestionData({ poll_id: +poll_id }));
    }
  }, [poll_id, questions, dispatch]);

  useEffect(() => {
    if (id && poll_id && Object.keys(questions).length > 0) {
      const question = questions[poll_id].find((x) => x.id === +id);
      if (question) {
        setHeader(`Edycja pytania ${id} zestawu '${selectedPoll.name}'`);

        setForm({
          id: +id,
          poll_id: question.poll_id,
          question_type: question.question_type,
          content: question.content,
          position: question.position,
          is_active: question.is_active,
        });
      }
    } else if (poll_id) {
      setHeader(`Nowe pytanie zestawu '${selectedPoll.name}'`);

      let newForm = JSON.parse(JSON.stringify(defaultForm));
      if (questions[poll_id]) {
        newForm.position = questions[poll_id].length + 1;
      }
      newForm.poll_id = +poll_id;
      setForm(newForm);
    }
  }, [id, poll_id, poll, questions, selectedPoll]);

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = { ...form };
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setForm(newForm);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    let formObj = form;

    let record = {
      ...formObj,
    };

    record.content = record.content.replaceAll('"', "”");

    await dispatch(saveQuestionData(record));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.section}>
        <h2>{header}</h2>
        <form onSubmit={submitHandler}>
          <div className={classes.row}>
            <TextField
              id="content"
              name="content"
              required
              label="Treść pytania"
              variant="outlined"
              onChange={handleFormChange}
              value={form.content}
            />
          </div>
          <div className={classes.row}>
            <TextField
              id="position"
              name="position"
              type="number"
              required
              label="Pozycja"
              variant="outlined"
              onChange={handleFormChange}
              value={form.position}
            />
          </div>
          <div className={classes.row} style={{ marginBottom: 50 }}>
            <Button variant="outlined" type="submit">
              Zapisz
            </Button>
          </div>
        </form>
        <ExistingQuestions questions={questions[poll_id]} poll_id={poll_id} />
      </div>
    </div>
  );
}

export default QuestionEditSection;

import { useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import ArticlePollModal from "./ArticlePollModal";
import classes from "./ArticleRow.module.css";

import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";

const ArticleRow = ({ article }) => {
  const articlePoll = useSelector((state) => state.pollArticle.articlePoll);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedPoll = !!articlePoll[article.id];
  const profileData = useSelector((state) => state.auth.profileData);

  let articlePollAllowed = false;
  if (profileData && profileData.privileges.includes(151)) {
    articlePollAllowed = true;
  }

  const handleArticlePoll = () => {
    setIsModalOpen(true);
  };

  const time = moment(article.publication_date).format("DD.MM.YYYY");
  return (
    <div className={classes.section}>
      <div className={classes.wrapper}>
        {article.image_url && (
          <a
            className={classes.imageWrapper}
            style={{ backgroundImage: "url(" + article.image_url + ")" }}
            href={article.image_url}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
          </a>
        )}
        {!article.image_url && (
          <div className={classes.imageWrapper}>
            <PhotoSizeSelectActualOutlinedIcon />
          </div>
        )}
        <div className={classes.contentRow}>
          <div className={classes.contentColumn}>
            <div className={classes.header}>
              <span className={classes.headerID}>[{article.id}]</span> {article.title}
            </div>
            <div className={classes.description}>{article.description}</div>
            <div className={classes.buttons}></div>
          </div>
          <div className={classes.lastColumn}>
            <div>{time}</div>
            {articlePollAllowed && (
              <div className={classes.editButton} onClick={handleArticlePoll} style={{ color: selectedPoll ? "green" : "black" }}>
                Przypisz
              </div>
            )}
            <div className={classes.readButton}>
              <a href={article.link} target="_blank" rel="noreferrer">
                Czytaj
              </a>
            </div>
          </div>
        </div>
      </div>
      {articlePollAllowed && <ArticlePollModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} article={article} />}
    </div>
  );
};

export default ArticleRow;

import { createSlice } from "@reduxjs/toolkit";

const pollCategorySlice = createSlice({
  name: "pollCategory",
  initialState: {
    pollCategory: {},
    categoryPoll: {},
  },
  reducers: {
    setPollCategory(state, action) {
      state.pollCategory = action.payload;
    },
    setCategoryPoll(state, action) {
      state.categoryPoll = action.payload;
    },
  },
});

export const pollCategoryActions = pollCategorySlice.actions;

export default pollCategorySlice.reducer;

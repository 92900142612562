import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PollAnswer from "./PollAnswer";

import classes from "./QuestionRow.module.css";

import EditIcon from "@mui/icons-material/Edit";

const QuestionRow = ({ question, poll_id }) => {
  const navigate = useNavigate();
  const [showAnswer, setShowAnswer] = useState(false);

  const showAnswerHandler = () => {
    setShowAnswer(!showAnswer);
  };

  const handleQuestionEdit = () => {
    navigate(`/question-edit/?id=${question.id}&poll_id=${poll_id}`);
  };

  return (
    <>
      <tr className={classes.tableRow}>
        <td width="30">{`${question.position})`}</td>
        <td>{question.content}</td>
        <td width="180" className={classes.pointerColumn}>
          <span className={classes.pointerElement} onClick={showAnswerHandler}>{`Odpowiedzi (${question.answers})`}</span>
          <EditIcon className={classes.pointerElement} sx={{ fontSize: 16, marginLeft: "10px" }} onClick={handleQuestionEdit} />
        </td>
      </tr>
      {showAnswer && (
        <tr>
          <td colSpan="3" className={classes.answerRow}>
            <PollAnswer question={question} poll_id={poll_id} />
          </td>
        </tr>
      )}
    </>
  );
};

export default QuestionRow;

import { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";

import classes from "./AuthForm.module.css";

const AuthForm = () => {
  const navigate = useNavigate();
  const loginInputRef = useRef();
  const passwordInputRef = useRef();

  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredLogin = loginInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    const credentials = {
      login: enteredLogin,
      password: enteredPassword,
    };

    setIsLoading(true);

    authCtx
      .sendRequest("/login/login/", credentials)
      .then((data) => {
        setIsLoading(false);
        const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
        authCtx.login(data.token, expirationTime.toISOString());
        navigate("/", true);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <section className={classes.auth}>
      <h1>Zaloguj</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor="login">Login</label>
          <input type="text" id="login" required ref={loginInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Hasło</label>
          <input
            type="password"
            id="password"
            required
            ref={passwordInputRef}
          />
        </div>
        <div className={classes.actions}>
          <button>Login</button>
          {isLoading && <p>Wczytywanie...</p>}
        </div>
      </form>
    </section>
  );
};

export default AuthForm;

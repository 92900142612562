import { uiActions } from "../slices/ui";
import { pollArticleActions } from "../slices/poll_article";
import { sendRequest } from "./auth";

export const fetchPollArticleData =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const pollArticleData = await dispatch(sendRequest("pollArticle", filters));

      dispatch(pollArticleActions.setPollArticle(pollArticleData.pollArticle));
      dispatch(pollArticleActions.setArticlePoll(pollArticleData.articlePoll));
    } catch (err) {
      console.log(err);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Loading data failed!",
        })
      );
    }
    return Promise.resolve();
  };

export const savePollArticleData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("pollArticle", record, "PUT"));

    if (result) {
      await dispatch(fetchPollArticleData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const addPollArticleData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("pollArticle/add", record, "PUT"));

    if (result) {
      await dispatch(fetchPollArticleData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

export const deletePollArticleData = (record) => async (dispatch) => {
  let fnResult = true;

  try {
    const result = await dispatch(sendRequest("pollArticle/delete", record, "PUT"));

    if (result) {
      await dispatch(fetchPollArticleData());

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sukces!",
          message: "Rekord zapisany!",
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Błąd!",
        message: "Błąd zapisu danych!",
      })
    );
    fnResult = false;
  }
  return Promise.resolve(fnResult);
};

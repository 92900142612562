import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import classes from "./DeleteModal.module.css";

import { useSelector, useDispatch } from "react-redux";

import { deletePollData } from "../../store/actions/poll";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "15px",
};

const DeleteModal = (params) => {
  const dispatch = useDispatch();
  const handleClose = () => params.setIsDeleteModalOpen(false);

  const pollFilters = useSelector((state) => state.poll.filters);

  const handleAccept = async (e) => {
    const saveResult = await dispatch(deletePollData({ id: params.poll.id }, pollFilters));
    if (saveResult) {
      params.setIsDeleteModalOpen(false);
    }
  };

  return (
    <div>
      <Modal open={params.isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {params.poll.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Czy chcesz usunąć zestaw i odpiąć go z kategorii?
          </Typography>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;

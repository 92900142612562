import { createSlice } from "@reduxjs/toolkit";

const answerSlice = createSlice({
  name: "answer",
  initialState: {
    answer: {},
    answersArray: [],
  },
  reducers: {
    setAnswer(state, action) {
      state.answer[action.payload.question_id] = action.payload.answer;
    },
    setAllAnswers(state, action) {
      state.answersArray = action.payload;
    },
  },
});

export const answerActions = answerSlice.actions;

export default answerSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const imageSlice = createSlice({
  name: "image",
  initialState: {
    image: [],
  },
  reducers: {
    setImage(state, action) {
      state.image = action.payload;
    },
  },
});

export const imageActions = imageSlice.actions;

export default imageSlice.reducer;

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "./slices/auth";

let logoutTimer;
const API_URL = "https://api-utils4.mobi-me.pl";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  updateExpirationTime: (expirationTime) => {},
  sendRequest: (endpoint, body, method) => {},
  profileData: {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 60000) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return { token: storedToken, duration: remainingTime };
};

const getProfileData = (token) => {
  return fetch(`${API_URL}/admin/currentUser/?token=${token}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return res.json().then((data) => {
          let errorMessage = "Błąd wczytywania danych!";
          if (data && data.error && data.error.message) {
            errorMessage = data.error.message;
          }
          throw new Error(errorMessage);
        });
      }
    })
    .then((data) => {
      return data;
    });
};

export const AuthContextProvider = (props) => {
  const dispatch = useDispatch();

  const tokenData = retrieveStoredToken();

  let initialToken;

  const [profileData, setProfileData] = useState();

  if (tokenData) {
    initialToken = tokenData.token;

    if (initialToken && !profileData) {
      getProfileData(initialToken).then((data) => {
        setProfileData(data.data);
        dispatch(authActions.setToken(initialToken));
        dispatch(authActions.setProfileData(data.data));
      });
    }
  }

  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    dispatch(authActions.setToken(null));
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");

    setProfileData(null);
    dispatch(authActions.setProfileData(null));

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, [dispatch]);

  const loginHandler = (token, expirationTime) => {
    setToken(token);
    dispatch(authActions.setToken(token));
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);

    getProfileData(token).then((data) => {
      setProfileData(data.data);
      dispatch(authActions.setProfileData(data.data));
    });
  };

  const updateExpirationTimeHandler = (expirationTime) => {
    localStorage.setItem("expirationTime", expirationTime);
    const remainingTime = calculateRemainingTime(expirationTime);

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const sendRequestHandler = async (endpoint, body, method = "POST") => {
    return fetch(`${API_URL}${endpoint}${!!token ? "?token=" + token : ""}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.ok) {
        const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
        updateExpirationTimeHandler(expirationTime);
        return res.json();
      } else {
        return res.json().then((data) => {
          let errorMessage = "Błąd wczytywania danych!";
          if (data && data.error && data.error.message) {
            errorMessage = data.error.message;
          }
          throw new Error(errorMessage);
        });
      }
    });
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    updateExpirationTime: updateExpirationTimeHandler,
    sendRequest: sendRequestHandler,
    profileData: profileData,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;

import React from "react";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useMountEffect from "../../hooks/useMountEffect";

import { fetchImageData } from "../../store/actions/image";
import { authActions } from "../../store/slices/auth";

import GalleryList from "./GalleryList";

import classes from "./GallerySection.module.css";

function GallerySection() {
  const dispatch = useDispatch();
  const forceReload = useSelector((state) => state.auth.forceReload);

  const imageData = useSelector((state) => state.image.image);

  const loadData = (force) => {
    if (force || (imageData && imageData.length <= 0)) {
      dispatch(fetchImageData());
    }
  };

  useMountEffect(() => {
    loadData(false);
  });

  useEffect(() => {
    if (forceReload) {
      dispatch(authActions.setForceReload(false));
      loadData(true);
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.wrapper} id="gallery-section-wrapper">
        <GalleryList imageData={imageData} />
      </div>
    </div>
  );
}

export default GallerySection;

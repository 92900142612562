import React, { useState } from "react";
import useMountEffect from "../../hooks/useMountEffect";

import { ToggleButtonGroup, ToggleButton, ImageList, ImageListItem, ImageListItemBar, IconButton, Tooltip } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import DetailsModal from "./DetailsModal";
import UploadImage from "./UploadImage";

import classes from "./GalleryList.module.css";

const BASE_URL = "https://quizyrmf.test-site.net.pl";

function GalleryList({ imageData }) {
  const [cols, setCols] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [imageType, setImageType] = useState("big");

  const listHeigth = window.innerHeight - 280;

  const imageSmall = imageData.filter((x) => x.image_width <= 200);
  const imageBig = imageData.filter((x) => x.image_width > 200);

  useMountEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const width = window.innerWidth;
    setCols(Math.floor(width / 250));
  };

  const handleImageClick = (item) => {
    setSelectedImage(item);
    setIsModalOpen(true);
  };

  const handleImageTypeChange = (event, type) => {
    if (type !== null) {
      setImageType(type);
    }
  };

  const checkImage = (image) => {
    if (image.size > 204800) {
      return true;
    } else {
      return false;
    }
  };

  const imageSet = imageType === "big" ? imageBig : imageSmall;

  return (
    <div>
      <UploadImage />
      <div className={classes.toggleGroup}>
        <ToggleButtonGroup fullWidth color="primary" value={imageType} exclusive onChange={handleImageTypeChange}>
          <ToggleButton value="big">Duże</ToggleButton>
          <ToggleButton value="small">Małe</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div>
        <ImageList sx={{ width: "100%", maxHeight: listHeigth }} cols={cols}>
          {imageSet.map((item) => (
            <ImageListItem key={item.image_url} className={classes.imagePiece} onClick={() => handleImageClick(item)}>
              <img
                src={`${BASE_URL}${item.image_url}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${BASE_URL}${item.image_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.image_name}
                loading="lazy"
              />
              <ImageListItemBar
                subtitle={item.image_name}
                actionIcon={
                  checkImage(item) && (
                    <IconButton sx={{ color: "rgba(255, 0, 0, 0.8)" }} aria-label={`info about ${item.image_name}`}>
                      <Tooltip title="Przekroczony rozmiar zdjęcia!">
                        <PriorityHighIcon />
                      </Tooltip>
                    </IconButton>
                  )
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
      <DetailsModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setSelectedImage={setSelectedImage} image={selectedImage} />
    </div>
  );
}

export default GalleryList;

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import store from "./store";
import { AuthContextProvider } from "./store/auth-context";

import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "moment/locale/pl";
import moment from "moment";

moment.locale("pl");

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterMoment} locale="pl">
    <Provider store={store}>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </Provider>
  </LocalizationProvider>,
  document.getElementById("root")
);

import React, { useEffect, useState } from "react";
import useMountEffect from "../../hooks/useMountEffect";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Typography, Checkbox, FormControlLabel, Tooltip } from "@mui/material";

import classes from "./ArticlePollModal.module.css";

import { useSelector, useDispatch } from "react-redux";

import { savePollArticleData } from "../../store/actions/poll_article";

const ArticlePollModal = ({ article, isOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.poll.survey);
  const articlePoll = useSelector((state) => state.pollArticle.articlePoll);

  const [border, setBorder] = useState(1);
  const [pollColumns, setPollColumns] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);

  useMountEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (articlePoll && Object.keys(articlePoll).length > 0 && articlePoll[article.id]) {
      setSelectedPoll(articlePoll[article.id]);
    }
  }, [article.id, articlePoll, isOpen]);

  const handleResize = () => {
    let width = window.innerWidth;
    let tmpBorder = 0;

    if (width <= 670) {
      tmpBorder = 1;
    } else if (width > 670 && width <= 990) {
      tmpBorder = 2;
    } else if (width > 990 && width <= 1140) {
      tmpBorder = 3;
    } else if (width > 1140 && width <= 1460) {
      tmpBorder = 4;
    } else if (width > 1460) {
      tmpBorder = 5;
    }

    setBorder(tmpBorder);
  };

  useEffect(() => {
    let pollItems = survey;

    pollItems = [...pollItems].sort((a, b) => (a.name > b.name && 1) || -1);

    if (selectedPoll && pollItems.findIndex((el) => el.id === selectedPoll) === -1) {
      pollItems.push({ id: selectedPoll, name: `[${selectedPoll}] Nieaktywny` });
    }

    let cnt = 0;

    let row_limit = Math.ceil(pollItems.length / border);
    let row_cnt = 0;

    let tempPollColumns = {};

    for (let step = 0; step < border; step++) {
      tempPollColumns[step] = [];
    }
    for (let i in pollItems) {
      tempPollColumns[cnt].push(pollItems[i]);
      row_cnt++;
      if (row_cnt >= row_limit) {
        cnt++;
        row_cnt = 0;
      }
    }
    setPollColumns(Object.values(tempPollColumns));
  }, [border, article, survey, selectedPoll]);

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedPoll(id);
    } else {
      setSelectedPoll(null);
    }
  };

  const handleClose = () => setIsModalOpen(false);

  const handleAccept = async (e) => {
    const saveResult = await dispatch(savePollArticleData({ article_id: article.id, poll: selectedPoll }));
    if (saveResult) {
      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title">
        <Box className={classes.box}>
          <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ margin: "10px 0" }}>
            {article.title}
          </Typography>
          <div className={classes.pollColumns}>
            {pollColumns.length > 0 &&
              pollColumns.map((col, index) => (
                <div key={`col_${index}`} className={classes.pollColumn}>
                  {col.map((row) => {
                    return (
                      <div key={`row_${row.id}`}>
                        <Tooltip title={row.name} placement="top" enterDelay={500} enterNextDelay={500}>
                          <FormControlLabel
                            sx={{ whiteSpace: "nowrap", maxWidth: "200px" }}
                            className={classes.controlLabel}
                            control={
                              <Checkbox
                                onChange={(event) => handleCheckboxChange(event, row.id)}
                                sx={{ padding: "5px" }}
                                checked={!!selectedPoll && selectedPoll === row.id}
                                size="small"
                                disabled={!!selectedPoll && selectedPoll !== row.id}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label={row.name}
                          />
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              ))}
          </div>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ArticlePollModal;
